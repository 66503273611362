<template>
  <div class="allcontens">
    <div class="content">
      <span class="smsphone">请输入已发送至 +86 <span id="J_PHONE">{{ formatMobile }}</span> 的6位验证码，有效期3分钟，如未收到，请尝试重新获取验证码。 </span>
        <div class="margin-top-20 margin-bottom-20">
          <van-password-input :value="verCode" :mask="false" :focused="showKeyboard" @focus="showKeyboard = true" />
          <van-number-keyboard v-model="numberKeyboard" :show="showKeyboard" @blur="showKeyboard = false" />
        </div>
      <span style="color:#FFAA32" class="smstime" v-if="unbindCode > 0" id="getcode">已发送(<span id="J_unbindCode">{{ unbindCode }}</span>)</span>
      <span class="btsend" id="getcodeagain" v-else @click="send()">重新发送</span>
      <div class="row justify-center">
        <van-button round type="info" size="small" class="margin-top-50 next-btn" @click="submit">确定</van-button>
      </div>
    </div>
  </div>
</template>
<script>
  import { sendSmsCode, unbindMobile } from '@/api/sign'
  import storage from '@/utils/storage'
  export default {
    name: 'SmsVerification',
    data() {
      return {
        timer: null,
        unbindCode: 120,
        constSecond: 120,
        bodyNo: '',
        verCode: '',
        showKeyboard: false,
        numberKeyboard: '',
        mobile: ''
      }
    },
    watch: {
      numberKeyboard: {
        handler(nVal) {
          this.verCode = nVal
        }
      }
    },
    created() {
      this.bodyNo = storage.getItem('bodyNo')
      this.mobile = this.$route.query.mobile
      const storageSecond = storage.getItem('unbindCode')
      
      if (storageSecond === undefined || storageSecond === null) {
        this.send()
      } else {
        this.time()
      }
    },
    computed: {
      formatMobile() {
        if (!this.mobile) return ''
        return this.mobile.substring(0, 3) + '****' + this.mobile.substring(7)
      }
    },
    methods: {
      async submit() {
        console.log(this.verCode)
        if (!this.verCode || this.verCode.length !== 6) {
          this.$toast('请输入6位数的验证码')
          return
        }
        const params = {
          bodyNo: this.bodyNo,
          verCode: this.verCode
        }
        const res = await unbindMobile(params)
        storage.setItem('unbindCode', 0)
        if (!res.success) {
          this.$toast.fail(res.respMag)
          return
        }
        this.$toast.success('解绑成功，将进入修改手机号码')
        setTimeout(() => {
          this.$router.push({ path: '/sign/update_mobile', query: { moible: this.mobile } })
        }, 2000)
      },
      async send() {
        const params = {
          bodyNo: this.bodyNo,
          mobile: this.mobile,
          codeType: 6 // 验证码类型，9-绑定手机，6-解绑手机
        }
        const res = await sendSmsCode(params)
        if (!res.success) {
          this.$toast.fail(res.respMag);
          return
        }
        storage.setItem('unbindCode', this.constSecond)
        this.time()
      },
      time() {
        const _this = this
        const storageSecond = storage.getItem('unbindCode')
        if(storageSecond === 0) {
          this.unbindCode = 0
          return
        }
        _this.unbindCode = storage.getItem('unbindCode') || _this.constSecond
        _this.timer = setInterval(function() {
          _this.unbindCode = _this.unbindCode - 1
          if (_this.unbindCode > 0) {
            storage.setItem('unbindCode', _this.unbindCode)
          } else {
            storage.setItem('unbindCode', 0)
            clearInterval(_this.timer);
          }
        }, 1000)
      }
    },
    destroyed() {
      this.timer && clearInterval(this.timer)
    }
  }
</script>
<style scoped>
  .smsbt {
    margin-top: 0.5rem;
    padding-bottom: 0.2rem;
    display: flex;
    justify-content: center;
    position: relative;
    /* justify-content: space-between; */
  }

  .smsphone {
    font-size: 0.4rem;
    font-weight: 500;
    color: #333;
    line-height: 0.8rem;
    letter-spacing: 0.02rem;
  }

  .smsinput {
    border: 0;
    outline: none;
    background-color: rgba(0, 0, 0, 0);
    padding-left: 0.4rem;
    box-sizing: border-box;
    width: 3rem;
    font-size: 0.3rem;
  }

  .smstime {
    font-size: 0.4rem;
  }

  .btsend {
    display: inline-block;
    font-size: 0.40rem;
    line-height: 0.5rem;
    color: #0084FE;
  }

  .smsnext {
    margin: 75px auto;
  }

  .tel {
    width: calc((100% - 1.8rem) / 6);
    border: none;
    outline: none;
    border-radius: 0;
    border: 0.02rem solid #ccc;
    margin-right: 0.2rem;
    height: 1rem;
    font-size: 0.6rem;
    text-align: center;
    color: #999;
  }

  .tels {
    width: calc((100% - 1.8rem) / 6);
    border: none;
    outline: none;
    border-radius: 0;
    border: 0.02rem solid red;
    margin-right: 0.2rem;
    height: 1rem;
    font-size: 0.6rem;
    text-align: center;
    color: #999;
  }

  .sms_input {
    position: absolute;
    width: 100%;
    /* background: red; */
    left: 0px;
    top: 0px;
    opacity: 0;
    caret-color: #fff;
    /* letter-spacing: 0.5rem; */
    border: none;
    outline: none;
    border-radius: 0;
    border-bottom: 0.02rem solid #ccc;
    margin-right: 0.2rem;
    height: 1rem;
    font-size: 0.1rem;
  }
</style>
